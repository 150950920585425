import React, { useEffect, useState } from "react";
import { Input } from "../../components/ui/input";
import { Bounce, toast, ToastContainer } from "react-toastify";
import { Button } from "../../components/ui/button.jsx";
import { Label } from "../../components/ui/label.jsx";
import axios from "axios";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { FadeLoader } from "react-spinners";
import Field from "../Form/Field";
import { editUser, getUser } from "../../api/userRequests";
import FileField from "../Form/FileField";

const EditUserUi = () => {
  const token = localStorage.getItem("token");
  const [user, setUser] = useState(null);
  const [isEditPasswordAble, isSetEditPasswordAble] = useState(true);
  const [pending, setPending] = useState(false);
  const params = useParams();

  useEffect(() => {
    getUser(params.id, setUser);
  }, [params.id]);

  console.log(user);

  // Formik setup
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstname: user?.user.firstname || "",
      lastname: user?.user.lastname || "",
      password: "",
      phone: user?.user.phone || "",
      position: user?.user.position || "",
      email: user?.user.email || "",
      website: user?.user.website || "",
      address: user?.user.address || "",
      facebook: user?.user.facebook || "",
      instagram: user?.user.instagram || "",
      xTwitter: user?.user.xTwitter || "",
      linkedIn: user?.user.linkedIn || "",
      avatar: null, // Initial state for the file input
    },
    onSubmit: (values) => {
      const formData = new FormData();

      // Append text values to formData
      formData.append("firstname", values.firstname);
      formData.append("lastname", values.lastname);
      if (values.password) formData.append("password", values.password);
      formData.append("phone", values.phone);
      formData.append("position", values.position);
      formData.append("email", values.email);
      formData.append("website", values.website);
      formData.append("address", values.address);
      formData.append("facebook", values.facebook);
      formData.append("instagram", values.instagram);
      formData.append("xTwitter", values.xTwitter);
      formData.append("linkedIn", values.linkedIn);

      // Append file to formData if it exists
      if (values.avatar) {
        formData.append("avatar", values.avatar);
      }

      editUser(formData, token, params.id, setPending);
    },
  });

  // Return a loading state if user data is still being fetched
  if (!user) {
    return (
      <div className="fixed h-screen top-0 left-0 bg-[#ffffff80] w-full">
        <div className=" absolute top-1/2 left-1/2">
          <FadeLoader
            color={"#00A4FF"}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white p-10 rounded-lg">
      <form onSubmit={formik.handleSubmit}>
        <h1 className="font-bold pb-3 text-xl">Personal information</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
          <div>
            <Field
              label="First Name *"
              type="text"
              name="firstname"
              formik={formik}
              value={formik.values.firstname}
              error={formik.errors.firstname}
              touched={formik.touched.firstname}
              id="firstname"
            />
            <Field
              label="Last Name *"
              type="text"
              name="lastname"
              formik={formik}
              value={formik.values.lastname}
              error={formik.errors.lastname}
              touched={formik.touched.lastname}
              id="lastname"
            />
            <Field
              label="Password *"
              type="password"
              name="password"
              formik={formik}
              value={formik.values.password}
              error={formik.errors.password}
              touched={formik.touched.password}
              id="password"
              disabled={isEditPasswordAble}
              isEditPassword={true}
              isSetEditPasswordAble={isSetEditPasswordAble}
            />
            <Field
              label="Phone *"
              type="tel"
              name="phone"
              formik={formik}
              value={formik.values.phone}
              error={formik.errors.phone}
              touched={formik.touched.phone}
              id="phone"
            />
          </div>
          <div>
            <Field
              label="Position *"
              type="text"
              name="position"
              formik={formik}
              value={formik.values.position}
              error={formik.errors.position}
              touched={formik.touched.position}
              id="position"
            />
            <Field
              label="Email *"
              type="email"
              name="email"
              formik={formik}
              value={formik.values.email}
              error={formik.errors.email}
              touched={formik.touched.email}
              id="email"
            />
            <Field
              label="Website"
              type="text"
              name="website"
              formik={formik}
              value={formik.values.website}
              error={formik.errors.website}
              touched={formik.touched.website}
              id="website"
            />
          </div>
          <div>
            <FileField
              label="Profile Picture"
              id="avatar"
              formik={formik}
              name="avatar"
              type="file"
              onChange={(event) =>
                formik.setFieldValue("avatar", event.currentTarget.files[0])
              }
            />
            <Field
              label="Address"
              type="text"
              name="address"
              formik={formik}
              value={formik.values.address}
              error={formik.errors.address}
              touched={formik.touched.address}
              id="address"
            />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 py-5">
          <div>
            <h1 className="font-bold pb-3 text-xl">Social Media information</h1>
            <Field
              label="Facebook"
              type="text"
              name="facebook"
              formik={formik}
              value={formik.values.facebook}
              error={formik.errors.facebook}
              touched={formik.touched.facebook}
              id="facebook"
            />
            <Field
              label="Instagram"
              type="text"
              name="instagram"
              formik={formik}
              value={formik.values.instagram}
              error={formik.errors.instagram}
              touched={formik.touched.instagram}
              id="instagram"
            />
            <Field
              label="Twitter"
              type="text"
              name="xTwitter"
              formik={formik}
              value={formik.values.xTwitter}
              error={formik.errors.xTwitter}
              touched={formik.touched.xTwitter}
              id="twitter"
            />
            <Field
              label="LinkedIn"
              type="text"
              name="linkedIn"
              formik={formik}
              value={formik.values.linkedIn}
              error={formik.errors.linkedIn}
              touched={formik.touched.linkedIn}
              id="linkedIn"
            />
          </div>
        </div>
        <Button
          className="mt-4 bg-[#2e1065] hover:bg-[#00A4FF] duration-500"
          type="submit"
        >
          Publish
        </Button>
      </form>

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="light"
      />
      {pending && (
        <div className="fixed h-screen top-0 left-0 bg-[#ffffff80] w-full">
          <div className=" absolute top-1/2 left-1/2">
            <FadeLoader
              color={"#00A4FF"}
              loading={pending}
              size={50}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default EditUserUi;
